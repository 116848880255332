.news {
    display: flex;
    justify-content: center;
}
.news .posts-sec {
    width: 1000px;
    margin-top: 64px;
}

.news .posts-sec .span {
    margin-bottom: 32px;
    display: block;
    color: #4b31a1;
}

.news .posts-sec .post {
    display: flex;
    flex-direction: row;
    border: 1px solid #ccc;
    margin-bottom: 32px;
}

.news .posts-sec .post img {
    width: 454px;
}

.news .posts-sec .post .content {
    padding: 24px;
    color: #4b31a1;
    line-height: 1.5
}

.news .posts-sec .post .content a {
    text-decoration: none;
}
.news .posts-sec .post .content .date {
    font-size: 12px;
}
.news .posts-sec .post .content h3 {
    margin: 16px 0;
    font-size: 24px;
    font-weight: lighter;
}

.news .posts-sec .post .content .text:hover {
    color: #00c9a7;
}

@media screen and (max-width: 600px) {
    .news .posts-sec {
        width: 100%;
        margin-top: 64px;
        padding: 0 24px;
    }

    .news .posts-sec .post {
        flex-direction: column;
        border: 0;
        border-bottom: 2px solid #ccc;
    }

    .news .posts-sec .post img {
        width: 100%;
    }

    .news .posts-sec .post .content {
        padding: 24px 12px;
    }

    .news .posts-sec .post .content span {
        font-size: 13px;
    }
}