.home .hero-sec {
    background-repeat: no-repeat;
    background-size:cover;
    background-image: url(../../assets/images/hero.jpg);
    background-attachment: fixed;
    position: relative;
    color: #fff;
}

.home .hero-sec .layout {
    width: 100%;
    height: 100%;
    padding: 64px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: rgba(137,29,235, 0.2);
    font-family: 'Nanum Gothic', sans-serif;
}

.home .hero-sec h1 {
    font-size: 72px;
    font-weight: lighter;
}

.home .hero-sec p {
    font-size: 24px;
    margin-top: 32px;
}


.home .news-sec h2 {
    text-align: center;
    margin-bottom: 64px;
    font-size: 40px;
    color: #4b31a1;
    font-weight: lighter;
}

.home .news-sec .posts {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.home .news-sec .post {
    position: relative;
    width: 454px;
    height: 400px;
    margin: 0 16px;
    color: #fff;
}

.home .news-sec .post img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.home .news-sec .post .layout {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(77,62,161, 0.3);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 28px;
}

.home .news-sec .post .layout span {
    font-size: 12px;
}
.home .news-sec .post .layout a {
    text-decoration: none;
    color: #fff;
}
.home .news-sec .post .layout h3 {
    font-size: 24px;
}

.home .reviews-sec {
    position: relative;
    background-color: #4b31a1;
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 24px 0;
    height: 550px;
}

.home .reviews-sec .arrow{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 32px;
}
.home .reviews-sec .review {
    width: 50%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-self: center;
    align-self: center;
}

.home .reviews-sec .review .title {
    font-size: 18px;
    font-weight: normal;
    text-align: center;
    margin-bottom: 40px;
}

.home .reviews-sec .review p {
    font-size: 22px;
    line-height: 1.4
}

.home .reviews-sec .review span {
    position: absolute;
    right: 0;
    bottom: -30px;
    font-size: 18px;
    margin-top: 8px;
}

.home .reviews-sec .dots {
    position: absolute;
    bottom: 24px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.home .contact-sec {
    text-align: center;
}

.home .contact-sec h2 {
    color: #4b31a1;
    font-size: 24px;
    font-weight: normal;
}

.home .contact-sec .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 200px;
    margin-top: 140px;
}

.home .contact-sec .content span {
    font-size: 40px;
    color: #00c9a7;
}

.home .contact-sec .content .emails {
    width: 500px;
    display: flex;
    flex-direction: column;
    text-align: center;
    color: #4b31a1;
    line-height: 2;
    font-size: 22px;
}

.home .under-contact-img {
    max-height: 500px;
}
.home .under-contact-img img{
    width: 100%;
    /* height: auto; */
}


@media screen and (max-width: 600px) {
    .home .hero-sec {
        background-position-x: -300px;
    }

    .home .hero-sec h1 {
        font-size: 32px;
        line-height: 1.5;
    }

    .home .hero-sec p {
        font-size: 18px;
        line-height: 1.8;
    }

    .home .news-sec .posts {
        flex-direction: column;
        align-items: center;
        justify-content: start;
        padding: 0 24px;
    }

    .home .news-sec .post {
        width: 100%;
        height: 300px;
        margin-bottom: 32px;
    }

    .home .reviews-sec {
        padding: 24px 0;
        height: 670px;
    }

    .home .reviews-sec .arrow {
        padding: 0 8px;
    }

    .home .reviews-sec .review {
        width: 75%;
    }

    .home .reviews-sec .review .title {
        margin-bottom: 24px;
    }

    .home .reviews-sec .review p {
        font-size: 16px;
        line-height: 1.5;
    }

    .home .contact-sec .content {
        flex-direction: column;
        justify-content: start;
        padding: 0;
        margin-top: 100px;
    }

    .home .contact-sec .content span {
        font-size: 24px;
        color: #00c9a7;
    }

    .home .contact-sec .content .emails {
        width: 100%;
        display: flex;
        flex-direction: column;
        text-align: center;
        color: #4b31a1;
        line-height: 2;
        font-size: 22px;
        margin-top: 64px;
    }
}