.statutes {
    background-image: url("../../assets/images/img2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    display: flex;
    justify-content: center;
    padding: 100px 0;
}

.statutes .content {
    padding: 64px;
    background-color: #4b31a1;
    width: 1000px;
}

.statutes .content h2 {
    font-size: 68px;
    font-weight: lighter;
    margin-bottom: 32px;
}

.statutes .content .statute {
    line-height: 1.8;
}

.statutes .content .statute h3 {
    font-weight: bold;
}

.statutes .content .statute p {
    margin-bottom: 32px;
    font-size: 20px;
}

.statutes .content .statute ol {
    margin: 0 20px;
}
.statutes .content .statute ul {
    list-style-type: upper-alpha;
    margin: 0 20px;
}

@media screen and (max-width: 600px) {
    .statutes {
        padding: 64px 0;
    }

    .statutes .content {
        padding: 24px;
        width: 95%;
    }

    .statutes .content h2 {
        font-size: 32px;
        font-weight: normal;
        text-align: center;
        margin-top: 24px;
    }
}