.F-O-T {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 80px;
}
.F-O-T h2{
    color: #00c9a7;
    margin: 32px 0;
    font-size: 64px;
    font-weight: lighter;
}

.F-O-T .form {
    color: #fff;
    background-color: #4b31a1;
    padding: 64px 300px;
    margin-bottom: 32px;
}

.F-O-T .form h3 {
    margin-bottom: 32px;
    font-size: 36px;
    font-weight: normal;
}

.F-O-T .form p {
    margin-bottom: 24px;
    line-height: 2;
    font-size: 18px;
}

@media screen and (max-width: 600px) {
    .F-O-T {
        padding: 0 ;
    }

    .F-O-T h2{
        font-size: 32px;
    }

    .F-O-T .form {
        padding: 32px 24px;
        margin-bottom: 12px;
    }

    .F-O-T .form h3 {
        margin-bottom: 32px;
        font-size: 22px;
        text-align: center;
        line-height: 1.5;
    }

}