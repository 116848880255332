.about {
    background-image: url("../../assets/images/img1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    display: flex;
    justify-content: center;
    padding: 100px 0;
}

.about .content {
    padding: 64px;
    background-color: #4b31a1;
    width: 1000px;
}

.about .content h2 {
    font-size: 68px;
    font-weight: lighter;
}

.about .content p {
    font-size: 20px;
    line-height: 1.5;
    margin-top: 32px
}

.about .content ul {
    margin: 0 32px;
    line-height: 1.5
}

@media screen and (max-width: 600px) {
    .about {
        padding: 64px 0;
    }

    .about .content {
        padding: 24px;
        width: 95%;
    }

    .about .content h2 {
        font-size: 32px;
        font-weight: normal;
        text-align: center;
        margin-top: 24px;
    }
}