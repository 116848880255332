.ul-alpha {
    list-style: upper-alpha;
}
.ul-dot {
    list-style: disc;
}
.data-policy .content b {
    font-style: italic;
}

.data-policy {
    background-image: url("../../assets/images/img3.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    display: flex;
    justify-content: center;
    padding: 100px 0;
}

.data-policy .content {
    padding: 64px;
    background-color: #4b31a1;
    width: 1000px;
}

.data-policy .content h2 {
    font-size: 64px;
    font-weight: normal;
    margin-bottom: 32px;
}

.data-policy .content h3 {
    font-size: 16px;
    margin: 32px 0;
}

.data-policy .content h4 {
    font-size: 16px;
    margin-top: 32px;
    margin-bottom: 8px;
}

.data-policy .content h5 {
    font-size: 20px;
    margin-top: 32px;
}

.data-policy .content p {
    font-size: 18px;
    margin-bottom: 32px;
    line-height: 1.8; 
}
.data-policy .content p a {
    color: #fff;
}

.data-policy .content ul, .data-policy .content ol {
    font-size: 18px;
    margin-bottom: 32px;
    line-height: 1.8;
    margin: 0 24px;
    display: inline-block;
}

@media screen and (max-width: 600px) {
    .data-policy {
        padding: 64px 0;
    }

    .data-policy .content {
        padding: 24px;
        width: 95%;
        overflow-wrap: anywhere;
    }

    .data-policy .content h2 {
        font-size: 32px;
        font-weight: normal;
        text-align: center;
        margin-top: 24px;
    }

    .data-policy .content h3 {
        font-size: 20px;
        margin: 24px 0;
    }

    .data-policy .content h4 {
        font-size: 18px;
        margin-top: 24px;
        margin-bottom: 4px;
    }

    .data-policy .content h5 {
        font-size: 16px;
        margin-top: 24px;
    }

    .data-policy .content p {
        font-size: 16px;
        margin-bottom: 24px;
        line-height: 1.5; 
    }

    .data-policy .content li {
        margin: 16px 0;
    }

    .data-policy .content ul, .data-policy .content ol {
        font-size: 16px;
        margin-bottom: 24px;
        line-height: 1.5;
        margin: 0 8px;
    }
}