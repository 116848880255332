.family-home {
    /*text-align: center;*/
    background-color: #4b31a1;
    color: #fff;
    margin: 0 80px;
    padding: 64px 300px;
}

h2{
    text-align: center;
    color: #00c9a7;
    margin: 32px 0;
    font-size: 64px;
    font-weight: lighter;
}

.family-home h3 {
    margin-bottom: 32px;
    font-size: 36px;
    font-weight: normal;
}

.family-home p {
    margin-bottom: 24px;
    line-height: 2;
    font-size: 18px;
}

@media screen and (max-width: 600px) {
    .family-home {
        padding: 32px 24px;
        margin: 10px 0;
    }

    h2{
        font-size: 32px;
    }

    .family-home h3 {
        margin-bottom: 32px;
        font-size: 22px;
        text-align: center;
        line-height: 1.5;
    }

}
