.single-post .container ul {
    margin: 24px 32px;
    line-height: 1.5
}
.single-post .container ul li {
    margin: 8px;
}
.single-post .container a {
    text-decoration: none;
}
.single-post .container a:hover {
    color: #00c9a7;
}
.single-post .container i:hover {
    color: #00c9a7;
}

.single-post .container i span {
    margin-left: 6px;
    font-weight: lighter;
}

.single-post .container {
    width: 940px;
    /* background-color: antiquewhite; */
    margin: 0 auto;
    color: #4b31a1;
}

.single-post .container .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* color: #4b31a1; */
    margin: 32px 0;
}

.single-post .container .post-sec {
    border: 1px solid #ccc;
    padding: 100px;
}

.single-post .container .post-sec .date {
    font-size: 14px;
}

.single-post .container .post-sec h3 {
    font-size: 32px;
    font-weight: lighter;
    margin: 32px 0 12px 0;
}

.single-post .container .post-sec p {
    font-size: 16px;
    line-height: 1.5;
    margin-top: 50px;
}

.single-post .container .post-sec img {
    margin: 64px 0;
    width: 100%;
}

.single-post .container .post-sec .socials {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding: 20px 0;
    margin: 24px 0;
}

.single-post .container .post-sec .socials a {
    margin-right: 24px;
    color: #4b31a1;
}

.single-post .container .comments-and-likes {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.single-post .container .latest-posts-sec {
    margin: 40px 0;
}

.single-post .container .latest-posts-sec .latest-posts-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
}

.single-post .container .latest-posts-sec .posts {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.single-post .container .latest-posts-sec .posts .post {
    border: 1px solid #ccc;
    width: 290px;
}

.single-post .container .latest-posts-sec .posts .post img {
    width: 100%;
    height: 160px;
}

.single-post .container .latest-posts-sec .posts .post .post-details {
    padding: 24px;
}
.single-post .container .latest-posts-sec .posts .post .comments-and-likes {
    margin-top: 32px;
    padding-top: 16px;
    border-top: 1px solid #ccc;
}
