.navbar {
    background-color: white;
    padding: 32px 80px;
}
.navbar .top-nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.navbar .top-nav .signup-btn {
    border: 1px solid #00c9a7;
    border-radius: 0;
    text-transform: capitalize;
    color: #00c9a7;
    background-color: transparent;
    padding: 12px 16px;
    font-size: 14px;
    text-decoration: none;
}
.navbar .top-nav .signup-btn:hover {
    background-color: #00c9a7;
    color: #FFFFFF;
    cursor: pointer;
}

.navbar i.menu {
    display: none;
}

.navbar a.not-active {
    color: #4b31a1 !important;
}

.navbar ul {
    list-style: none;
}

.navbar hr {
    border: 0;
    border-bottom: 1px solid #4b31a1;
    margin: 20px 0;
    height: 1px;
}

.navbar .bottom-nav ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    list-style: none;
}

.navbar .bottom-nav ul li a {
    margin-right: 24px;
    color: #4b31a1;
    font-size: 18px;
    font-weight: normal;
    text-decoration: none;
}
.navbar ul li a:hover {
    color: #00c9a7;
}

.navbar ul li button:hover {
    color: #00c9a7;
}

.navbar .bottom-nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.navbar .bottom-nav i {
    margin-left: 12px;
    color: #000;
}

.navbar .bottom-nav ul .nav-btn {
    margin-right: 18px;
    color: #4b31a1;
    font-size: 18px;
    font-weight: normal;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    position: relative;
    /* padding: 0 8px; */
}

.navbar .dropdown {
    position: absolute;
    background-color: #fff;
    top: 20px;
    left: -8px;
    z-index: 10;
    padding: 8px;
}

.navbar .dropdown-forms {
    /* top: 30px; */
    width: max-content;
}

.navbar .dropdown ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.navbar .dropdown ul li {
    margin: 6px 0;
}

.navbar .mob-drop-clicker {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 600px) {
    .navbar {
        padding: 32px 24px;
        border-bottom: 1px solid #4b31a1;
        display: flex;
        flex-direction: row;
        justify-content: center;
        position: relative;
    }

    .navbar .top-nav {
        flex-direction: column;
        margin-bottom: 40px;
    }

    .navbar .top-nav .signup-btn {
        margin-top: 12px;
        padding: 6px 16px;
        position: absolute;
        right: 24px;
        bottom: 24px;
    }

    .navbar .logo img {
        width: 100px;
    }
    
    .navbar i.menu {
        display: block;
        color: #4b31a1;
        position: absolute;
        right: 24px;
        font-size: 24px;
    } 

    .navbar hr {
        display: none;
    }

    .navbar .bottom-nav {
        display: none;
    }

    .navbar .mob-nav {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background-color: #fff;
        z-index: 1;
    }

    .navbar .mob-nav i.close {
        position: absolute;
        right: 24px;
        top: 24px;
        font-size: 24px;
        color: #4b31a1;
    }

    .navbar .mob-nav .ul1 {     
        height: 100%;   
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .navbar .mob-nav .ul2 {     
        width: 100%;   
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .navbar .mob-nav .ul2 a {
        width: 60%;
        /* font-size: 22px; */
        /* clear: both;
        display: inline-block;
        overflow: hidden;
        white-space: nowrap; */
    }

    .navbar .mob-nav .ul1 li {
        text-align: center;
        width: 100%;
    }
    .navbar .mob-nav .ul2 li {
        text-align: center;
        width: 100%;
    }

    .navbar .mob-nav ul li {
        margin: 12px 0;
    }

    .navbar .mob-nav ul li a {
        color: #4b31a1;
        text-decoration: none;
    }

    .navbar .mob-nav ul li a.active {
        color: #00c9a7;
    }

    .navbar .mob-nav ul .nav-btn {
        color: #4b31a1;
        font-size: 16px;
        border: 0;
        background-color: transparent;
        cursor: pointer;
        position: relative;
    }
    
}