.footer-sec {
    text-align: center;
    padding-top: 64px;
    padding-bottom: 12px;
}

.footer-sec .socials {
    margin-bottom: 32px;
}

.footer-sec .socials a{
    margin-left: 12px;
    color: #000;
}

.footer-sec .title {
    color: #4b31a1;
    font-size: 14px;
}