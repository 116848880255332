* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  scroll-behavior: smooth;
  /* font-weight: normal ; */
}

.pd-y {
  padding: 90px 0;
}

.App {
}


/* ============ FONTS ============ */

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400&family=Quicksand:wght@300;400&display=swap');