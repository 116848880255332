.support-us {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.support-us .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 1000px;
    max-width: 70%;
}

.support-us .content h2 {
    text-align: center;
    font-size: 66px;
    color:#00c9a7;
    margin-bottom: 32px;
    font-weight: lighter;
}

.support-us .content span {
    text-align: center;
    color: #4b31a1;
    margin-bottom: 100px;
}

.support-us .content .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.support-us .content .col {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 250px;
    text-align: center;
    line-height: 1.3;
}

.support-us .content .col .img {
    height: 300px;
    width: 250px;
}

.support-us .content .col h3 {
    font-weight: lighter;
    font-size: 38px;
    height: 100px;
    margin-bottom: 32px;
    color: #00c9a7;
}

.support-us .content .col p {
    font-weight: normal;
    font-size: 20px;
    color: #4b31a1;
}

@media screen and (max-width: 600px) {
    .support-us .content {
        min-width: 100%;
        max-width: 100%;
        padding: 0 24px;
    }

    .support-us .content h2 {
        font-size: 32px;
        margin-bottom: 24px;
    }

    .support-us .content .row {
        display: flex;
        flex-direction: column;
        /* justify-content: flex-start; */
        width: 100%;
    }

    .support-us .content .col {
        width: 100%;
        text-align: center;
        line-height: 1.3;
        margin-bottom: 64px;
    }

    .support-us .content .col .img {
        height: auto;
        width: 100%;
        margin-bottom: 24px;
    }

    .support-us .content .col h3 {
        font-size: 24px;
        height: auto;
        margin-bottom: 24px;
    }

    .support-us .content .col p {
        font-size: 16px;
        display: block;
        text-align: start;
    }
}